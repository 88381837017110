<template>
  <div class="dashboard">
    <div class="row mb-4">
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p  class="text-uppercase fw-medium text-muted text-truncate mb-0" >
                 TOTAL CASES
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4" >
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.cases.total}}
                </h4>
                <router-link to="/cases" class="small text-decoration-underline"
                  >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded fs-3">
                  <i class="ri-bar-chart-box-line text-info"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                  DEPARTMENTS
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4" >
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.departments}}
                </h4>
                <router-link to="/departments" class="small text-decoration-underline" >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-primary rounded fs-3">
                  <i class="ri-team-line text-primary"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0" >
                  ALL USERS
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.users.total}}
                </h4>
                <router-link to="/users" class="text-decoration-underline" >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-warning rounded fs-3">
                  <i class="ri-group-line text-warning"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                  TOTAL LAWYERS
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h5 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.users.lawyer }}
                </h5>
                <router-link to="/users?role=lawyer" class="small text-decoration-underline"
                  >View list</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-danger rounded fs-3">
                  <i class="ri-bar-chart-box-line text-danger"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <statistic-apex-count
          title="TODAY'S CASES"
          :count-end="result.cases.today"
          :total="result.cases.total"
          :figure="result.cases.today"
        />
      </div>
      <div class="col-md-6">
        <statistic-apex-count
          title="PENDING CASES"
          :count-end="result.cases.pending"
          :total="result.cases.total"
          :figure="result.cases.pending"
          chart-color="#FFB02f"
        />
      </div>
      <div class="col-md-6">
        <statistic-apex-count
          title="APPEALED CASES"
          :count-end="result.cases.appealed"
          :total="result.cases.total"
          :figure="result.cases.appealed"
          />
      </div>
      <div class="col-md-6">
        <statistic-apex-count
          title="CLOSED CASES"
          :count-end="result.cases.closed"
          :total="result.cases.total"
          :figure="result.cases.closed"
          />
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-12">
        <case-list />
      </div>
    </div>
  </div>
</template>

<script>

import StatisticApexCount from '@/components/widgets/StatisticApexCount.vue';
import CaseList from './cases/CaseList.vue';

export default {
    name: 'dashboard',
    components:{
      StatisticApexCount,
      CaseList,
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      result(){
        return this.$store.state.dashboardData
      }
    },
    created(){
      this.$http.get('/analytics')
      .then((response) => {
        if(response.data.success){
          this.$store.commit('UPDATE_DASHBOARD', response.data.data)
        }
      })
    }
}
</script>

