
<template>
<form autocomplete="off" class="">
    <div class="row g-lg-4 g-3">
    <div class="col-sm-6 col-lg-4">
        <label class="form-label">Created between</label>
        <flat-pickr
            v-model="data.created_at"
            :config="rangeConfig"
            placeholder="Select date"
            class="form-control flatpickr-input"
        ></flat-pickr>
    </div>
    <div class="col-sm-6 col-lg-4">
        <label>Category</label>
        <select class="form-select" v-model="data.case_category_id"
            name="input-category" placeholder="--select category--">
            <option disabled value="" key="-1">-- select category--</option>
            <option :value="category.id" v-for="(category, catIndex) in categoryList" 
            :key="catIndex">{{ category.title }}</option>
        </select>
    </div>
    <div class="col-sm-6 col-md-4">
        <div class="">
        <label>Case Court </label>
        <multiselect 
        mode="single"
        searchable
        valueProp="id"
        trackBy="name" label="name"
        :options="courts" 
        v-model="data.court_id" placeholder="--select court--"></multiselect>
        </div>
    </div>  
    <div class="col-sm-6 col-md-4">
        <div class="">
        <label>Presiding Judge </label>
        <multiselect 
            mode="single"
            searchable
            valueProp="id"
            trackBy="name" label="name"
            :options="judges" 
            v-model="data.judge_id" placeholder="--select  judge--"></multiselect>
        </div>
    </div>
    <div class="col-sm-6 col-md-4">
        <div class="">
        <label>Case Department </label>
        <multiselect 
            mode="single"
            searchable
            valueProp="id"
            trackBy="title" label="title"
            :options="departments" 
            v-model="data.department_id" placeholder="--select department--"></multiselect>
        </div>
    </div>

    <div class="col-sm-6 col-md-4">
        <label class="form-label">
            Adjourned Date
        </label>
        <flat-pickr
            v-model="data.adjourned_date"
            :config="dateConfig"
            placeholder="Select date"
            class="form-control flatpickr-input"
        ></flat-pickr>
    </div>
    </div>
    <div class="row mt-3 justify-content-end">
    <div class="col-12">
        <div class="mt-3 text-end">
        <b-button variant="primary" class="me-3"
            @click.prevent="getList()"
            type="button"> Get List </b-button>

        <b-button variant="dark" 
            @click.prevent="resetForm()"
            type="button"> Reset </b-button>
        </div>
    </div>
    </div>
</form>
</template>
    
<script>
import Multiselect from '@vueform/multiselect'
import flatPickr from "vue-flatpickr-component";

export default {
    components: {
    Multiselect,
    flatPickr
    },
    data() {
    return {
        data: {
            created_at: "",
            case_category_id: "",
            court_id: "",
            judge_id: "",
            department_id: "",
            adjourned_date: "",
        },
        dateConfig:{
            mode: "range",
            dateFormat: "Y-m-d",
            altInput: true,
            showMonths: 1,
            altFormat: "F j, Y",
        },
        rangeConfig: {
            mode: "range",
            dateFormat: "Y-m-d",
            altInput: true,
            showMonths: 2,
            altFormat: "F j, Y",
            // minDate: "today",
            maxDate: new Date().fp_incr(1)
        },
        mirrorData: null
    }
    },
    computed:{
        categoryList(){
            return this.$store.state.caseCategory.list
        },
        departments(){
            return this.$store.state.department.list
        },
        judges(){
            return this.$store.state.judge.list
        },
        courts(){
            return this.$store.state.court.list
        },
    },
    methods: {
        getList() {
            var payload = Object.fromEntries(
                Object.entries(this.data).filter(([_, v]) => !!v )
            );
            this.$emit('startSearch', payload)
        },
        resetForm() {
            this.data = {...this.mirrorData}
            this.$emit('resetFilters')
        },
    },
    created(){
        this.$store.dispatch('caseCategory/fetchAllCategories')
        this.$store.dispatch('judge/fetchAllJudges')
        this.$store.dispatch('court/fetchAllCourts')
        this.$store.dispatch('department/fetchAllDepartments')
    },
    mounted(){
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    },
}
</script>
